import React from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Hero, Container, Heading } from 'react-bulma-components'

// import Layout from '../../components/layouts/blog'
// import styles from '../../styles'
import presets from '../../utils/presets'
import { rhythm /*, scale */ } from '../../utils/typography'

import Layout from '../../components/layouts/index'
import SEO from '../../components/seo'

const { Fragment } = React

class BlogPage extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges

    return (
      <Layout
        location={this.props.location}
        className="index"
        snipperLogo={this.props.data.snipperLogo.childImageSharp.fluid}
      >
        <SEO
          title="News blog snippet tool for mac"
          description="👍 SnipperApp 2 blog provides ✅ The latest news ✅ Lifestyle tips ✅ Useful how-to articles that help you stay productive. Read more... ☝"
        />
        <Fragment>
          <Hero size="fullheight">
            <Hero.Body>
              <Container style={{ maxWidth: '37rem' }}>
                <Heading size={1} renderAs="h1">
                  SnipperApp 2 Blog
                </Heading>
                <ul
                  css={{
                    marginBottom: rhythm(2),
                    marginTop: rhythm(2),
                    marginLeft: 0,
                    listStyle: `none`
                  }}
                >
                  {posts.map(post => (
                    <li
                      key={post.node.fields.slug}
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: rhythm(0.5)
                      }}
                    >
                      <h2
                        css={{
                          overflowWrap: 'break-word',
                          width: '70%',
                          fontSize: rhythm(1)
                        }}
                      >
                        <Link to={post.node.fields.slug}>
                          {post.node.frontmatter.title}
                        </Link>
                      </h2>
                      <span
                        css={{
                          // color: styles.colors.light,
                          display: `flex`,
                          [presets.Tablet]: {
                            float: `right`,
                            marginLeft: `1rem`
                          },
                          float: 'right',
                          paddingTop: '.5rem'
                        }}
                      >
                        {post.node.frontmatter.date}
                      </span>
                    </li>
                  ))}
                </ul>
              </Container>
            </Hero.Body>
          </Hero>
        </Fragment>
      </Layout>
    )
  }
}

BlogPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}

export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        social {
          twitter
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { ne: true } }
        fileAbsolutePath: { regex: "/(blog)/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    snipperLogo: file(
      absolutePath: { regex: "/snipper-logo-512×512@2x.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
